<template>
  <form
    novalidate
    class="change-password-form"
    @submit.prevent="sendPassword"
  >
    <VInput
      name="newPassword"
      type="password"
      :placeholder="$t('Password')"
      :value="form.password"
      :generalError="rulesForGeneralError"
      :inputDisabled="inputDisabled"
      :required="true"
      class="change-password-form__input"
      @change-input-value="changeForm($event, 'password')"
      @input-errors="onInputErrors"
      @show-error="onBlurInputWithError"
      @hide-error="onFocusInputWithError"
    >
      <IconLock />
    </VInput>

    <ErrorsLog
      :errorsShowed="errorsShowed"
      :errors="errors"
      :generalError="generalError"
      :booleanRulesForGeneralError="booleanRulesForGeneralError"
      @error-enter="erorrEnter"
      @error-after-leave="errorAfterLeave"
      @general-error-enter="generalErrorEnter"
      @general-error-after-leave="generalErrorAfterLeave"
    />

    <VButton :isLoading="isLoading">
      {{ $t('Save & Log in') }}
    </VButton>
  </form>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { validate } from '@/utils/validate';
import { errorsLog } from '@/mixins/errorsLog';
import { authForms } from '@/mixins/authForms';
import ErrorsLog from '@/components/auth/ErrorsLog';
import VInput from '@/components/ui/Auth/VInput';
import VButton from '@/components/ui/Auth/VButton';
import IconLock from '@/assets/icons/lock9.svg';

const formTemplate = {
  token: null,
  email: null,
  password: null,
  password_confirmation: null,
};

export default {
  name: 'ChangePasswordForm',
  components: {
    ErrorsLog,
    VInput,
    VButton,
    IconLock,
  },
  mixins: [
    errorsLog,
    authForms,
  ],

  data: () => ({
    url: '',
    form: { ...formTemplate },
    timeOut: null,
  }),
  computed: {
    statusAllFields() {
      return this.form.password
        && this.generalError.text === validate.generalErrors.allHighlightedFieldsMustBeFilled;
    },
    urlIsValid() {
      return this.url.searchParams.get('token')
        && this.url.searchParams.get('email');
    },
    formIsValid() {
      return this.form.password
        && !this.errors.length;
    },
  },

  created() {
    this.url = new URL(window.location.href);

    if (!this.urlIsValid) {
      this.$router.push({ name: 'reset-password' });
    }

    this.form.token = this.url.searchParams.get('token');
    this.form.email = this.url.searchParams.get('email');

    this.SET_EMAIL(this.form.email);
  },

  methods: {
    ...mapMutations('auth', [
      'SET_EMAIL',
    ]),
    ...mapActions('auth', [
      'changePassword',
      'signIn',
      'signInSuccess',
    ]),

    async sendPassword() {
      if (!this.formIsValid) {
        return this.setGeneralError(true, validate.generalErrors.allHighlightedFieldsMustBeFilled);
      }

      this.setFormLoadingState(true);
      this.clearErrors();

      this.form.password_confirmation = this.form.password;

      try {
        const response = await this.changePassword(this.form);
        this.setFormLoadingState(false);

        if (!response.data.success) {
          return this.setGeneralError(true, validate.generalErrors.tryAgain);
        }

        try {
          const response = await this.signIn(this.form);
          if (!response.data.success) {
            return this.setGeneralError(true, validate.generalErrors.tryAgain);
          }

          this.signInSuccess(response.data.token);
        } catch(e) {
          const error = e.response
            ? e.response.data.message
            : e.message;

            if (error === 'Network Error') {
              return this.setGeneralError(true, validate.generalErrors.connectionFalse);
            }

            this.setGeneralError(true, validate.generalErrors.tryAgain);
        }
      } catch(e) {
        this.setFormLoadingState(false);

        const error = e.response
          ? e.response.data.message
          : e.message;

        if (error === 'Network Error') {
          return this.setGeneralError(true, validate.generalErrors.connectionFalse);
        }

        this.setGeneralError(true, validate.generalErrors.tryAgain);
      }
    },
  },

  metaInfo() {
    return {
      title: this.$t('Reset password'),
    };
  },
  i18n: {
    messages: {
      'en': {
        'Password': 'Password',
        'Save & Log in': 'Save & Log in',
        'Reset password': 'Reset password',
      },
      'ru': {
        'Password': 'Пароль',
        'Save & Log in': 'Сохранить и войти',
        'Reset password': 'Сброс пароля',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.change-password-form {
  display: grid;
}

.change-password-form__input {
  margin-bottom: 20px;
}
</style>
